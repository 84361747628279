<template>
  <section id="channel-view-selector">
    <div
      v-for="channel in activeChannels"
      :key="channel._id"
      class="channel"
      :class="{
        active: activeChannelView === channel.knownType,
        disabled:
          channel.knownType !== 'all' &&
          !botMessageInEdit.isEditing &&
          !botMessageInEdit.exceptInView
      }"
      @click="activeChannel(channel)"
    >
      <img
        class="channel__logo"
        :src="channelLogo(channel)"
        :alt="channel.knownType"
        :title="channel.knownType"
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ChannelViewSelector',
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('integrations', ['integrations']),
    ...mapGetters('botMaker', ['activeChannelView', 'activeVersion', 'botMessageInEdit', 'bot', 'botVersions']),
    activeChannels() {
      let isActive = false;
      if (this.botVersions && this.bot) {
        const version = this.botVersions.find(
          v => v._id === this.bot.activeVersion
        )
        if (version && version.active) {
          isActive = true
        }
      }
      // return this.integrations.filter(i => i.active)
      return this.integrations
        .filter(i => i.active && ((i.version && i.version._id === this.activeVersion) || (!i.version && isActive) || i.type === 'web'))
        .map(i => {
          i.knownType = i.knownType === 'web' ? 'all' : i.knownType
          return i
        })
    }
  },
  methods: {
    ...mapMutations('botMaker', [
      'SET_ACTIVE_CHANNEL_VIEW',
      'SET_MESSAGE_IN_EDIT',
      'RESET_BOT_MESSAGE',
      'SET_MESSAGE_IN_EDIT',
      'SET_MESSAGE_IN_EDIT_RID'
    ]),
    channelLogo(channel) {
      let image = ''
      try {
        image = require(`@/assets/images/icons/channels/${channel.knownType.toLowerCase()}.svg`)
      } catch (error) {
        image = require(`@/assets/images/icons/channels/message-square.svg`)
      }
      return image
    },
    activeChannel(channel) {
      // Verifico si el mensaje que esta editando en el cambio de canales
      this.SET_ACTIVE_CHANNEL_VIEW(channel.knownType)

      // Si, esto es "feo" pero el ciclo de digest de la función anterior
      // exige que utilice esta capsula
      // TODO: ver manera mas "linda"
      setTimeout(() => {
        // Verifico que el tab no sea visible en el activeChannel correspondiente
        if (this.botMessageInEdit.isVisibleMob == false) {
          // Esto es lo mas "feo" de este código
          // Pero a priori es necesario sin terminar de entender todo el mutation
          window.activeChannelForce = this.activeChannelView

          // Otra capsula "fea"
          // TODO: Mejorar
          setTimeout(() => {
            // Obtengo el mensaje por responseId y el pasamano de la plataforma
            this.SET_MESSAGE_IN_EDIT_RID(this.botMessageInEdit.type.responseId)
          }, 1)

          this.botMessageInEdit.exceptInView = true
        }
      }, 1)
    }
  }, 
  watch: {
    activeVersion() {
      
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss">
#channel-view-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
  margin: 20px 0;

  .channel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 50%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    background-color: white;
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    margin: 5px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      background-color: #fafafa;
    }

    &.active {
      // box-shadow: 0px 0px 8px rgba(var(--vs-primary), 0.5);
      border: solid 2px rgba(var(--vs-primary), 0.8);
      transform: scale(1.1);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &__logo {
      height: 36px;
    }
  }
}
</style>
